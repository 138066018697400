.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.splashscreen {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  color: var(--neon) !important;
  z-index: 5;
}

.splashscreen::before {
  content: '';
  position: absolute;
  background-color: var(--forest);
  top: 0;
  bottom: auto;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  animation: pullright 500ms ease-out;
  animation-delay: 2100ms;
}

.splashscreen::after {
  content: '';
  position: absolute;
  background-color: var(--forest);
  top: 0;
  bottom: auto;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  animation: pullleft 500ms ease-out;
  animation-delay: 2100ms;
}

.splashscreen>h1 {
  text-align: center;
  font-size: 3rem;
  animation: rotate 2000ms ease-out infinite;
}

.splashscreen>p {
  text-align: center;
  font-size: 3rem;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes pullleft {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(1000px);
  }
}

@keyframes pullright {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-1000px);
  }
}


.home-button {
  font-family: graffiti !important;
  font-size: 2rem !important;
  margin-top: 1em;
  margin-left: 1em;
  color: white;
  text-decoration: none;
  position: fixed;
  top: 1em;
  left: 1em;
  transition: all 0.5s ease-in-out;
}

.home-button:hover {
  letter-spacing: 0.5em;
}

.App-mobile {
  display: none;
}

.main-scene {
  background-image: url(Images/aesthetik-side-background.jpg);
  background-size: cover;
  background-position: 0%;
  width: 100%;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  overflow: hidden;
}

.main-scene>.scene-group {
  cursor: pointer;
  font-size: 15rem;
  background-size: cover;
  background-position: -10%;
  transition: all 500ms ease-in-out;
  position: relative;
  text-shadow: 0.03em 0.06em var(--lime);
  color: var(--neon);
  text-decoration: none;
  filter: blur(1px);
}

.main-scene>.scene-group:nth-child(1):hover::after {
  background-image: url('/src/Images/streetlights.jpg');
  background-size: inherit;
  content: 'LINKS';
  background-position: 0%;
  position: absolute;
  filter: blur(2px);
}

.main-scene>.scene-group:nth-child(2):hover::after {
  background-image: url('/src/Images/citysun.jpg');
  background-size: inherit;
  content: 'MUSIC';
}

.main-scene>.scene-group:nth-child(3):hover::after {
  background-image: url('/src/Images/tokyoNight.png');
  background-size: inherit;
  background-position: 0%;
  content: 'GALLERY';
  font-size: 10rem;
}

.main-scene>.scene-group:nth-child(4):hover::after {
  background-image: url('/src/Images/kakashijpg.jpg');
  background-position: 0%;
  background-size: inherit;
  content: 'SIDE';
}

.main-scene>.scene-group:nth-child(1):hover::before {
  content: 'LINKS';
}

.main-scene>.scene-group:nth-child(2):hover::before {
  content: 'MUSIC';
}

.main-scene>.scene-group:nth-child(3):hover::before {
  content: 'GALLERY';
  font-size: 10rem;
}

.main-scene>.scene-group:nth-child(4):hover::before {
  content: 'SIDE';
}

.main-scene>.scene-group:hover::after {
  position: absolute;
  font-size: 15rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 2;
  animation: noise-anim 1s infinite linear alternate-reverse;
}

.main-scene>.scene-group:hover::before {
  position: absolute;
  font-size: 15rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 3;
  animation: noise-anim-2 1s infinite linear alternate;
}

@keyframes noise-anim {
  0% {
    text-shadow: 0.03em 0.06em red;
  }

  30% {
    text-shadow: 0.06em 0.02em red;
  }

  70% {
    text-shadow: 0.02em 0.03em red;
  }

  100% {
    text-shadow: 0.05em 0.04em blue;
  }
}

@keyframes noise-anim-2 {
  0% {
    text-shadow: 0.03em 0.06em red;
  }

  30% {
    text-shadow: 0.06em 0.02em blue;
  }

  70% {
    text-shadow: 0.02em 0.03em blue;
  }

  100% {
    text-shadow: 0.05em 0.04em red;
  }
}

@keyframes zoom {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes backspin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes backgroundpan {
  0% {
    background-position: 0% 100%;
  }

  25% {
    background-position: 50% 50%;
  }

  50% {
    background-position: 100% 0%;
  }

  75% {
    background-position: 50% 50%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes musicback {
  75% {
    background-size: 300% 600%;
  }
}

@keyframes primary {
  from {
    right: -5%;
  }

  to {
    right: -100%;
  }
}

@keyframes secondary {
  from {
    right: 100%;
  }

  to {
    right: 0%;
  }
}

@Keyframes scaleup {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes musicupdown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.Links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  background: radial-gradient(white 8%, transparent 8%);
  background-position: 0% 0%;
  background-size: 5vmin 5vmin;
  backdrop-filter: blur(5px);
  animation: backgroundpan 70s linear infinite;
  padding: 2em;
}

.Links-header {
  align-self: center;
  font-size: 10em;
  color: white;
  animation: zoom 750ms cubic-bezier(.69, .31, .39, 1.61);
  margin-top: 0.005em;
  margin-bottom: 0.02em;
  text-shadow: 0.05em 0.1em var(--lime);
}

.Links-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-content: space-evenly;
  gap: 1em;
  margin: 1em;
  justify-content: center;
}

.Links-link {
  font-size: 2em;
  list-style-type: none;
  text-decoration: none;
  position: relative;
  height: 6em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Links-link>.Links-link-back {
  position: absolute;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  height: 100%;
  aspect-ratio: 1;
  background: linear-gradient(30deg, transparent 40%, rgba(42, 41, 40, .85) 40%) no-repeat 100% 0,
    linear-gradient(60deg, rgba(42, 41, 40, .85) 60%, transparent 60%) no-repeat 0 100%,
    repeating-radial-gradient(#2a2928, #2a2928 4px, #ada9a0 5px, #2a2928 6px);
  background-size: 50% 100%, 100% 50%, 100% 100%;
  animation: spin 1s linear infinite;
  margin: 2rem;
}

.Links-link:nth-child(1)>.Links-link-back {
  background: linear-gradient(30deg, transparent 40%, rgba(42, 41, 40, .45) 40%) no-repeat 100% 0,
    linear-gradient(60deg, rgba(42, 41, 40, .45) 60%, transparent 60%) no-repeat 0 100%,
    repeating-radial-gradient(#a41cab, #a41cab 4px, #ada9a0 5px, #a41cab 6px);
  background-size: 50% 100%, 100% 50%, 100% 100%;

}

.Links-link:nth-child(2)>.Links-link-back {
  background: linear-gradient(30deg, transparent 40%, rgba(42, 41, 40, .45) 40%) no-repeat 100% 0,
    linear-gradient(60deg, rgba(42, 41, 40, .45) 60%, transparent 60%) no-repeat 0 100%,
    repeating-radial-gradient(#a41cab, #a41cab 4px, #ada9a0 5px, #a41cab 6px);
  background-size: 50% 100%, 100% 50%, 100% 100%;

}

.Links-link>a {
  position: absolute;
  top: 50%;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
}

.Music {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Music-heading {
  width: 100vw;
  position: relative;
}

.Music-heading>.goback {
  position: absolute;
  display: inline-block;
  top: 1em;
  left: 1em;
  font-weight: 700;
}

.Music-header {
  text-align: center;
  font-size: 10em;
  color: white;
  animation: zoom 750ms cubic-bezier(.69, .31, .39, 1.61);
  margin-top: 0.005em;
  margin-bottom: 0.02em;
  text-shadow: 0.05em 0.1em var(--neon);
}

.Music-body {
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 4em;
  top: 20vh;
  width: 100vw;
  height: 80vh;
  overflow-x: hidden;
}

.Music-link {
  text-decoration: none;
  color: white;
  font-family: graffiti;
  text-shadow: 0.05em 0.1em var(--lime);
  font-size: 1em;
  word-wrap: break-word;
}

.frames {
  background-color: rgba(42, 41, 40, .45);
}

.Music-frame {
  position: absolute;
  /* 
  top: auto;
  align-self: center;
  margin: 2em; */
  width: 100vw;
  height: 80%;
  display: flex;
  gap: 1em;
  overflow: hidden;
}

.Music-frame.primary {
  height: 100%;
  aspect-ratio: 1;
  animation: primary 30s linear infinite;
}

.Music-frame.secondary {
  height: 100%;
  aspect-ratio: 1;
  animation: secondary 30s linear infinite;
}

.frames {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  gap: 1em;
  right: 0%;
}

.frame-container {
  position: relative;
  animation: musicupdown 7s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.frames:nth-child(0)>.frame-container {
  animation-delay: 0.5s;
}

.frames:nth-child(1)>.frame-container {
  animation-delay: 0.8s;
}

.frames:nth-child(2)>.frame-container {
  animation-delay: 0.2s;
}

.frames:nth-child(3)>.frame-container {
  animation-delay: 1.5s;
}

.frames:nth-child(4)>.frame-container {
  animation-delay: 1s;
}

.frames:nth-child(5)>.frame-container {
  animation-delay: 0.6s;
}


.Music-background {
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: repeating-radial-gradient(var(--neon), var(--lime), black);
  background-position: 0%;
  background-size: 100% 100%;
  animation: musicback 7s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.catch-music {
  position: fixed;
  bottom: 0;
  margin-bottom: 2%;
  text-align: center;
  align-self: center;
  cursor: pointer;
}

.catch-music:hover {
  animation: scaleup 2s ease-in-out;
}

.Music-playing {
  font-family: graffiti;
  font-size: 2em;
  color: white;
  text-shadow: 0.3em 0.2em var(--lime);
  position: relative;
}

.Music-playing::before {
  content: '';
  left: -2em;
  position: absolute;
  border-radius: 50%;
  height: 100%;
  aspect-ratio: 1;
  background: linear-gradient(30deg, transparent 40%, rgba(42, 41, 40, .85) 40%) no-repeat 100% 0,
    linear-gradient(60deg, rgba(42, 41, 40, .85) 60%, transparent 60%) no-repeat 0 100%,
    repeating-radial-gradient(#2a2928, #2a2928 4px, #ada9a0 5px, #2a2928 6px);
  background-size: 50% 100%, 100% 50%, 100% 100%;
  animation: spin 1s linear infinite;
}

.side {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.side-head {
  text-align: center;
  font-size: 10em;
  color: white;
  animation: zoom 750ms cubic-bezier(.69, .31, .39, 1.61);
  margin-top: 0.005em;
  margin-bottom: 0.02em;
  text-shadow: 0.05em 0.1em var(--neon);
}

.gallery {
  color: white;
}

.Gallery-heading {
  width: 100vw;
  position: relative;
}

.Gallery-heading>.goback {
  position: absolute;
  display: inline-block;
  font-size: 2rem !important;
  text-decoration: none;
  margin-top: 1em;
  margin-left: 1em;
  z-index: 10;
  top: 1em;
  left: 1em;
  font-weight: 700;
  color: white;
  transition: all 0.5s ease-in-out;
}

.goback:hover {
  letter-spacing: 0.5em;
}

.Gallery-header {
  text-align: center;
  font-size: 10em;
  color: white;
  /*animation: zoom 750ms ease-in-out;*/
  margin-top: 0.005em;
  margin-bottom: 0.02em;
  text-shadow: 0.05em 0.1em var(--neon);
}

.coming-soon {
  color: white;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
}


#space,
.stars {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.stars {
  background-image:
    radial-gradient(2px 2px at 20px 30px, #eee, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 40px 70px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 50px 160px, #ddd, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 90px 40px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 130px 80px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 160px 120px, #ddd, rgba(0, 0, 0, 0));
  background-repeat: repeat;
  background-size: 200px 200px;
  animation: zoom 5s infinite;
  opacity: 0;
}

.stars:nth-child(1) {
  background-position: 50% 50%;
  animation-delay: 0s;
}

.stars:nth-child(2) {
  background-position: 20% 60%;
  animation-delay: 1s;
}

.stars:nth-child(3) {
  background-position: -20% -30%;
  animation-delay: 2s;
}

.stars:nth-child(4) {
  background-position: 40% -80%;
  animation-delay: 3s;
}

.stars:nth-child(5) {
  background-position: -20% 30%;
  animation-delay: 4s;
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0.5);
    animation-timing-function: ease-in;
  }

  85% {
    opacity: 1;
    transform: scale(2.8);
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    transform: scale(3.5);
  }
}