@media (max-width: 900px) {

    .App {
        display: none;
    }

    .App-mobile {
        display: flex !important;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(Images/aesthetik-side-background.jpg);
        background-size: contain;
        justify-content: center;
    }

    .App-mobile-body {
        position: relative;
        margin-top: 20%;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 10px;
        color: white;
        height: 20%;
    }

    .app-mobile-button {
        border-radius: 8px;
    }
}